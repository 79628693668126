import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import "./style.scss";
//images
import bee from "../../../../images/social_bee.svg";
import vk from "../../../../images/social_vk.svg";
import ok from "../../../../images/social_ok.svg";
import wa from "../../../../images/social_wa.svg";
import tg from "../../../../images/social_tg.svg";
import vb from "../../../../images/social_vb.svg";
import sk from "../../../../images/social_sk.svg";
import yt from "../../../../images/social_yt.svg";
import ya from "../../../../images/social_ya.svg";
import tt from "../../../../images/social_tt.svg";
import nav from "../../../../images/social_nav.svg";
import yamap from "../../../../images/social_yamap.svg";
import gmap from "../../../../images/social_gmap.svg";
import gmail from "../../../../images/social_gmail.svg";
import mail from "../../../../images/social_mail.svg";
import bz from "../../../../images/logo_bez_mobile_golden.svg";

interface ISocial {
    name: string;
    icons: string[];
    iconsWidth?: number;
}

export const SOCIAL_SERVICES: ISocial[] = [
    {
        name: "Билайн",
        icons: [bee]
    },
    {
        name: "Социальные сети",
        icons: [vk, ok]
    },
    {
        name: "Мессенджеры",
        icons: [wa, tg, vb, sk]
    },
    {
        name: "Видео",
        icons: [yt, tt]
    },
    // {
    //     name: "Музыку",
    //     icons: [vk, ya]
    // },
    {
        name: "Карты",
        icons: [yamap, gmap, nav]
    },
    {
        name: "Почтовые сервисы",
        icons: [gmail, mail, ya]
    },
    {
        name: "Безлимит",
        icons: [bz]
    }
];

export default observer(function SocialIconsServices({
    isMain = false,
    isPageTarrif = false,
    isPagePlan = false,
    isPagePlans = false,
    socialArrayNew = false,
    subscriptionFee
}: TSocialIconsServices) {
    const {t} = useTranslation();
    const SOCIAL_SERVICES_NEW: ISocial[] = [];

    function getSocialServices() {
        if (socialArrayNew) {
            JSON.parse(JSON.stringify(SOCIAL_SERVICES)).forEach((obj: ISocial, index: number) => {
                if (obj.name === "Социальные сети") {
                    obj.name = "Соц сети";
                    SOCIAL_SERVICES_NEW.push(obj);
                } else if (obj.name === "Почтовые сервисы") {
                    obj.name = "Почту";
                    SOCIAL_SERVICES_NEW.push(obj);
                } else {
                    SOCIAL_SERVICES_NEW.push(obj);
                }
            });
            return SOCIAL_SERVICES_NEW;
        } else {
            return SOCIAL_SERVICES;
        }
    }

    return (
        <>
            <div
                className={`ant-collapse-content-box-main
                ${isPagePlan && "ant-collapse-content-box-plan"}
                ${isPagePlans && "ant-collapse-content-box-plans"}
                `}
            >
                {getSocialServices().map((item, index) => {
                    if (item.name === "Видео" && _.lte(subscriptionFee, 1100)) {
                        return null;
                    }

                    return (
                        <>
                            <Row
                                className={`social-icons-row ${
                                    isPageTarrif && "social-icons-row-tariff"
                                } `}
                            >
                                <Col className="social-icons-col ">
                                    {item.icons.map((icon) => (
                                        <img className="social-icon" src={icon} alt="logo" />
                                    ))}
                                </Col>
                                <Typography className="social-text">
                                    {t(`Безлимит на ${item.name}`)}
                                </Typography>
                            </Row>
                        </>
                    );
                })}
            </div>
            {isPagePlans && _.lte(subscriptionFee, 1100) && (
                <Typography as="div" className="subscription-fee-590-margin" />
            )}
        </>
    );
});

type TSocialIconsServices = {
    isMain?: boolean;
    isPageTarrif?: boolean;
    isPagePlan?: boolean;
    isPagePlans?: boolean;
    socialArrayNew?: boolean;
    subscriptionFee?: number;
};
